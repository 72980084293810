/************+ FOOTER ****************/
.landing .footer {
    height: var(--footer-height);
    max-height: var(--footer-height);
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: 0 2vw;
    border-top: 1px solid #efefef;
    overflow: hidden;
}

.landing .footer .logo svg {
    height: 2vw;
    width: auto;
    fill: #005eff;
}

.landing .footer .footer-items {
    display: flex;
    flex-direction: row;
    flex: 1;
    justify-content: space-evenly;
    font-size: var(--font-menu-item);
}

.landing .footer .footer-items .footer-item {
    cursor: pointer;
}

.landing .footer .footer-items .footer-item:hover {

    color: var(--color-accent-mkt)
}

.landing .footer .copyright {
    font-size: var(--font-menu-item);
}

/**********************************************/
/************+ FOR MOBILE ONLY ****************/
/**********************************************/
@media only screen and (max-width: 500px) {

    /************+ FOOTER ****************/
    .landing .footer .footer-item {
        display: none;
    }

    .landing .footer .logo svg {
        height: 5vw;
    }
    .landing .footer .copyright {
        font-size: var(--font-main-text);
    }
        
}