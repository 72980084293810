.landing {
    --font-main-text: 1.2vw;
    --font-main-text-small: 0.7vw;
    --font-menu-item: 0.9vw;
    --font-very-large: 1.9vw;
    --font-job-title: 1.2vw;
    --font-box-text: 1vw;
    --font-section-title: 2.9vw;
    --font-mobile-side-menu: 3.3vw;
    --section-padding-horizontal: 5vw;
    --section-padding-vertical: 2vw;
    --header-height: 7vw;
    --header-logo-height: 3vw;
    --footer-height: 4vw;
}

@media only screen and (max-width: 500px) {

    .landing {
        --font-main-text: 2.5vw;
        --font-main-text-small: 2vw;
        --font-menu-item: 0.9vw;
        --font-very-large: 3vw;
        --font-job-title: 4vw;
        --font-box-text: 1vw;
        --font-section-title: 4vw;
        --section-padding-horizontal: 5vw;
        --section-padding-vertical: 6vw;
        --header-height: 15vw;
        --header-logo-height: 6vw;
        --footer-height: 12vw;
    }
}
