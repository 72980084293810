
.landing {
  display: flex;
  flex-direction: column;
}
.landing .moto-header {
  padding: 1vw 1vw 0.4vw 1vw;
  border-bottom: 0.5vw solid #FFDF00;
  box-sizing: content-box;
}
.landing .moto-header svg {
  height: 2vw;
  width: auto;
}

.section {
  padding: 2vw 5vw;

  --font-size-headline: 2.7vw;
  --font-size-text: 1.7vw;
  --font-size-small-text: 1vw;
}
.section.grad {
  background: var(--color-content-primary-grad);
}
.section.horizontal {
  display: flex;
  flex-direction: row;
}

.section .headline {
  font-size: var(--font-size-headline);
}
.section .standard-text {
  font-size: var(--font-size-text);
}

/*******************************************************/
/* SECTION PUB */
/*******************************************************/
.section-pub {
  display: flex;
  flex-direction: row;
  position: relative;
  color: white;
}

.section-pub svg {
  fill: white;
}

.curvy-background {
  color: var(--color-content-bg);
  position: absolute;
  overflow: hidden;
  left: 0;
  right: 0;
  height: 12vh;
  bottom: -1px;
}

.section-pub .left {
  display: flex;
  flex-direction: column;
  width: 40vw;
  max-width: 40vw;
}
.section-pub .left .headline {
  margin: 3vw 0;
  margin-right: -15vw;
}
.section-pub .left .payoff {
  margin-bottom: 3vw;
}
.section-pub .left .partnership {
  display: flex;
  flex-direction: row;
  align-items: center;
}
.section-pub .left .partnership .pleggit-logo svg {
  height: 5vw;
  width: auto;
}
.section-pub .left .partnership .moto-logo svg {
  height: 3vw; 
  width: auto;
}

.section-pub .right {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  width: 20vw;
}

.section-pub .right .download-text {
  font-size: var(--font-size-text);
  white-space: nowrap;
  margin-left: -7vw;
}

.section-pub .right .download-buttons {
  display: flex;
  flex-direction: column;
  margin: 1vw 0;
  margin-left: -7vw;
}
.section-pub .right .download-buttons svg {
  width: 12vw;
  height: auto;

}

/*******************************************************/
/* SECTION HOW */
/*******************************************************/
.section-how {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.section-how .heading {
  font-size: var(--font-size-text);
  text-align: center;
  margin: 2vw 0 5vw 0;
}

.section-how .carousel {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
}
.section-how .carousel .step {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0 2vw;
}

.section-how .carousel .step img {
  width: 15vw;
  height: auto;
}

.section-how .carousel .step .text {
  font-size: var(--font-size-small-text);
  text-align: center;
  padding: 1vw 3vw 0 3vw;
}

/*******************************************************/
/* SECTION DOWNLOAD */
/*******************************************************/
.section-download {
  display: flex;
  flex-direction: row;
  background-color: #e7f3fb;
  color: #005eff;
  margin-top: 2vw;
}

.section-download .headline {
  width: 60vw;
  padding: 3vw;
}

.section-download .stores {
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.section-download .stores svg {
  width: 12vw;
  height: auto;
}

/*******************************************************/
/*******************************************************/
/*******************************************************/
/* MOBILE */
@media only screen and (max-width: 900px) {
  .section {
    --font-size-headline: 8vw;
    --font-size-text: 6vw;
    --font-size-small-text: 4vw;
  }
  .landing .moto-header {
    padding: 2vw 3vw 0.7vw 3vw;
    border-bottom: 0.9vw solid #FFDF00;
  }
  .landing .moto-header svg {
    height: 4vw;
  }
  /*******************************************************/
  /* SECTION PUB */
  /*******************************************************/
  .section-pub {
    flex-direction: column;
    align-items: stretch;
  }
  .section-pub .left {
    width: auto;
    max-width: none;
    text-align: center;
    justify-content: center;
  }
  .section-pub .left .partnership {
    justify-content: center;
    margin-top: 7vw;
  }
  .section-pub .left .partnership .pleggit-logo svg {
    height: 20vw;
  }
  .section-pub .left .partnership .moto-logo svg {
    height: 10vw;
  }
  .section-pub .left .headline {
    margin-right: 0;
    white-space: normal;
    margin-top: 7vw;
  }
  .section-pub .left .payoff {
    margin-top: 7vw;
  }
  .section-pub .center-image {
    display: none;
  }
  .section-pub .right {
    margin-bottom: 3vw;
    margin-top: 7vw;
    width: auto;
    align-items: center;
  }
  .section-pub .right .download-text {
    margin-left: 0;
  }
  .section-pub .right .download-buttons {
    margin: 0; 
    margin-top: 2vw;
  }
  .section-pub .right .download-buttons svg {
    width: 50vw;
  }
  .section-pub .curvy-background {
    display: none;
  }

  /*******************************************************/
  /* SECTION HOW */
  /*******************************************************/
  .section-how .heading {
    text-align: center;
    margin: 2vw 0 10vw 0;
  }
  .section-how .carousel {
    display: flex;
    flex-direction: column;
  }
  .section-how .carousel .step {
    padding: 0;
  }
  .section-how .carousel .step img {
    width: 60vw;
    height: auto;
  }
  .section-how .carousel .step .text {
    font-size: var(--font-size-small-text);
    text-align: center;
    padding: 2vw 15vw 10vw 15vw;
  }

  /*******************************************************/
  /* SECTION DOWNLOAD */
  /*******************************************************/
  .section-download {
    display: flex;
    flex-direction: column;
  }
  .section-download .headline {
    width: auto;
    text-align: center;
    padding: 3vw 10vw 3vw 10vw;
  }
  .section-download .stores div {
    display: flex;
    flex-direction: row;
    justify-content: center;
  }
  .section-download .stores svg {
    width: 50vw;
  }
}
